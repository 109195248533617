
import { Icon } from "@iconify/vue2";
export default {
  name: "BottomNavigation",

  components: {
    Icon,
  },

  data: () => ({
    navValue: null,
    navItems: [
      // {
      //   value: "rewards",
      //   name: "Rewards",
      //   icon: "fluent:trophy-20-regular",
      //   to: "/rewards",
      // },
      {
        value: "community",
        name: "Community",
        icon: "fluent:people-community-20-regular",
        to: "/community",
      },
      {
        value: "schedule",
        name: "Schedule",
        icon: "fluent:calendar-20-regular",
        to: "/schedule",
      },
      { value: "home", name: "Home", icon: "fluent:home-20-regular", to: "/" },
      {
        value: "social-feed",
        name: "Social",
        icon: "fluent:comment-multiple-20-regular",
        to: "/social",
      },
      {
        value: "to-do",
        name: "To Do",
        icon: "fluent:task-list-20-regular",
        to: "/to-do",
      },
    ],
  }),
};
