import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import store from "@/store";
import router from "@/router";
import { EventBus } from "@/main";
import { DateTime } from "luxon";

export interface ApiResponse<T> {
  data: T | null;
  error: AxiosError<unknown, any> | null;
  message: string | null;
}

const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const convertToDate = (obj: any) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof Object) {
      convertToDate(value);
    } else if (typeof value === "string" && dateRegex.test(value)) {
      obj[key] = DateTime.fromISO(value).toJSDate();
    }
  }
};

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_ACHIEVER_API_URL,
    });

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data } = response;
        if (data instanceof Object) {
          convertToDate(data);
        }
        return response;
      },
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          await store.dispatch("logout");
          router.push("/login");
        }
        return Promise.reject(error);
      }
    );
  }

  async get<T>(url: string): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${store.getters["getToken"]}` },
      });
      return {
        data: response.data,
        error: null,
        message: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error: error as AxiosError<unknown, any>,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  }

  async post<T>(
    url: string,
    payload: any,
    multiPart?: boolean
  ): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${store.getters["getToken"]}`,
            "Content-Type": multiPart
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
      if (JSON.parse(JSON.stringify(response.data)).pointsEarned)
        EventBus.$emit(
          "confetti",
          "Task Completed",
          "⭐",
          `${JSON.parse(JSON.stringify(response.data)).pointsEarned} point${
            JSON.parse(JSON.stringify(response.data)).pointsEarned === 1
              ? ""
              : "s"
          } earned`
        );
      if (JSON.parse(JSON.stringify(response.data)).goalPointsEarned)
        EventBus.$emit(
          "confetti",
          "Goal Completed",
          "🎯",
          `${JSON.parse(JSON.stringify(response.data)).goalPointsEarned} point${
            JSON.parse(JSON.stringify(response.data)).goalPointsEarned === 1
              ? ""
              : "s"
          } earned`
        );
      return { data: response.data, error: null, message: null };
    } catch (error: any) {
      return {
        data: null,
        error: error as AxiosError<unknown, any>,
        message: error.response.data.message,
      };
    }
  }

  async put<T>(
    url: string,
    payload: any,
    multiPart?: boolean
  ): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.put(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${store.getters["getToken"]}`,
            "Content-Type": multiPart
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
      if (JSON.parse(JSON.stringify(response.data)).pointsEarned)
        EventBus.$emit(
          "confetti",
          "Task Completed",
          "⭐",
          `${JSON.parse(JSON.stringify(response.data)).pointsEarned} point${
            JSON.parse(JSON.stringify(response.data)).pointsEarned === 1
              ? ""
              : "s"
          } earned`
        );
      if (JSON.parse(JSON.stringify(response.data)).goalPointsEarned)
        EventBus.$emit(
          "confetti",
          "Goal Completed",
          "🎯",
          `${JSON.parse(JSON.stringify(response.data)).goalPointsEarned} point${
            JSON.parse(JSON.stringify(response.data)).goalPointsEarned === 1
              ? ""
              : "s"
          } earned`
        );
      return { data: response.data, error: null, message: null };
    } catch (error: any) {
      return {
        data: null,
        error: error as AxiosError<unknown, any>,
        message: error.response.data.message,
      };
    }
  }

  async putOrPost<T>(
    url: string,
    payload: any,
    id?: number | null,
    multiPart?: boolean
  ): Promise<ApiResponse<T>> {
    if (id) {
      return this.put(`${url}/${id}`, payload, multiPart);
    } else {
      return this.post(url, payload, multiPart);
    }
  }

  async delete<T>(url: string): Promise<ApiResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.delete(url, {
        headers: { Authorization: `Bearer ${store.getters["getToken"]}` },
      });
      return { data: response.data, error: null, message: null };
    } catch (error: any) {
      return {
        data: null,
        error: error as AxiosError<unknown, any>,
        message: error.response.data.message,
      };
    }
  }
}

const apiService = new ApiService();

export default apiService;
