import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import { EventBus } from "@/main";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("../views/WelcomeView.vue"),
    meta: { protected: true },
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { protected: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
    meta: { protected: true },
  },
  {
    path: "/student",
    name: "student",
    component: () => import("../views/StudentView.vue"),
    meta: { protected: true },
  },
  {
    path: "/student-course",
    name: "student-course",
    component: () => import("../views/StudentCourseView.vue"),
    meta: { protected: true, customButtons: true },
  },
  {
    path: "/teacher",
    name: "teacher",
    component: () => import("../views/TeacherView.vue"),
    meta: { protected: true },
  },
  {
    path: "/teacher-class",
    name: "teacher-class",
    component: () => import("../views/TeacherClassView.vue"),
    meta: { protected: true, customButtons: true },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("../views/ScheduleView.vue"),
    meta: { protected: true },
  },
  {
    path: "/to-do",
    name: "to-do",
    component: () => import("../views/ToDoView.vue"),
    meta: { protected: true },
  },
  {
    path: "/steps",
    name: "steps",
    component: () => import("../views/StepsView.vue"),
    meta: { protected: true },
  },
  {
    path: "/social",
    name: "social",
    component: () => import("../views/SocialView.vue"),
    meta: { protected: true },
  },
  {
    path: "/community",
    name: "community",
    component: () => import("../views/CommunityView.vue"),
    meta: { protected: true },
  },
  {
    path: "/goal",
    name: "goal",
    component: () => import("../views/GoalView.vue"),
    meta: { protected: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.protected)) {
    if (store.getters.isLoggedIn) {
      return next();
    }
    EventBus.$emit("error", "Session expired, please log in");
    return next("/login");
  }
  return next();
});

export default router;
