
import Vue from "vue";
import { EventBus } from "./main";
import ApiService, { ApiResponse } from "@/services/ApiService";
import { VuePwaInstallMixin, BeforeInstallPromptEvent } from "vue-pwa-install";
import { DateTime } from "luxon";
import CryptoJS from "crypto-js";
// import { getToken, onMessage } from "firebase/messaging";
// import { messaging } from "@/services/firebaseService";
import AppBar from "./components/AppBar.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
import AppCelebrationDialog from "./components/AppCelebrationDialog.vue";
import AppQRCodeDialog from "./components/AppQRCodeDialog.vue";
import SecureImage from "@/components/SecureImage.vue";
import { Icon } from "@iconify/vue2";
import confetti from "canvas-confetti";
import { Notification } from "@/common/classes";

export default Vue.extend({
  name: "App",

  mixins: [VuePwaInstallMixin],

  components: {
    AppBar,
    BottomNavigation,
    AppCelebrationDialog,
    AppQRCodeDialog,
    SecureImage,
    Icon,
  },

  computed: {
    appCssVars(): object {
      return {
        "--app-padding-bottom": this.showApp ? "56px" : "0px",
      };
    },
    userQRCode(): string {
      return CryptoJS.AES.encrypt(
        JSON.stringify({
          id: this.$store.getters.getUser?.id,
          t: new Date().getTime(),
        }),
        process.env.VUE_APP_SECRET
      ).toString();
    },
  },

  data: () => ({
    showApp: false,
    appBarCustomButtons: false,
    windowWidth: window.innerWidth,
    // appPaddingBottom: "0px",
    installPrompt: null as BeforeInstallPromptEvent | null,
    showInstallPrompt: false,
    showedInstallPrompt: false,
    menu: false,
    notificationsDrawer: false,
    notifications: [] as Notification[],
    showCelebrationDialog: false,
    showQRCodeDialog: false,
    pageTitle: "",
    pageIcon: "",
    snackbar: {
      color: "blue",
      text: "",
      visible: false,
    },
    appMenuItems: [
      {
        name: "My Profile",
        icon: "fluent:person-circle-20-regular",
        methodName: "myProfile",
      },
      // {
      //   name: "My Rewards",
      //   icon: "fluent:trophy-20-regular",
      //   methodName: "myRewards",
      // },
      {
        name: "My Courses",
        icon: "fluent:hat-graduation-20-regular",
        methodName: "myCourses",
        userType: "Student",
      },
      {
        name: "My Classes",
        icon: "fluent:screen-person-20-regular",
        methodName: "myClasses",
        userType: "Teacher",
      },
      // {
      //   name: "My QR Code",
      //   icon: "fluent:qr-code-20-regular",
      //   methodName: "showMyQRCode",
      // },
      // {
      //   name: "Scan QR Code",
      //   icon: "fluent:barcode-scanner-20-regular",
      //   methodName: "scanQRCode",
      // },
      // {
      //   name: "Settings",
      //   icon: "fluent:settings-20-regular",
      //   methodName: "settings",
      // },
      {
        name: "Log Out",
        icon: "fluent:arrow-exit-20-regular",
        methodName: "logOut",
      },
    ],
    celebrationTitle: "",
    celebrationEmoji: "",
    celebrationMessage: "",
  }),

  methods: {
    promptInstall() {
      this.showedInstallPrompt = true;
      this.showInstallPrompt = false;
      this.installPrompt?.prompt();
      this.installPrompt?.userChoice.then((choiceResult) => {
        this.installPrompt = null;
      });
    },
    // handleResize() {
    //   if (
    //     this.windowWidth <= 768 &&
    //     screen.availHeight - window.innerHeight > 112
    //   ) {
    //     // mobile browser
    //     this.appPaddingBottom = this.showApp ? "112px" : "0px";
    //   } else {
    //     this.appPaddingBottom = this.showApp ? "56px" : "0px";
    //   }
    // },
    hideApp(hide: boolean) {
      if (hide === true) this.showApp = false;
    },
    updatePageTitle(pageTitle: string) {
      this.pageTitle = pageTitle;
    },
    updatePageIcon(pageIcon: string) {
      this.pageIcon = pageIcon;
    },
    appMenuItemClick(methodName: string) {
      //TODO: call method by string name
      switch (methodName) {
        case "myProfile":
          return this.$router.push("/profile");
        case "myCourses":
          return this.$router.push("/student");
        case "myClasses":
          return this.$router.push("/teacher");
        case "showMyQRCode":
          this.menu = false;
          return this.showMyQRCode();
        case "scanQRCode":
          this.menu = false;
          return this.scanQRCode();
        case "settings":
          return this.settings();
        case "logOut":
          return this.logOut();
        default:
          break;
      }
    },
    // TODO
    // celebration(message: string) {
    relativeDateTime(date: Date | null): string | null {
      return date ? DateTime.fromJSDate(date).toRelative() : null;
    },
    celebration() {
      this.showCelebrationDialog = true;
      confetti({
        particleCount: 250,
      });
    },
    showMyQRCode() {
      this.showQRCodeDialog = true;
    },
    scanQRCode() {
      console.log("Scan QR Code");
    },
    settings() {
      this.$router.push("/settings");
    },
    logOut() {
      this.$router.push("/login");
      setTimeout(() => {
        this.$store.dispatch("logout"), 1000;
      });
    },
    async getNotifications() {
      const getNotificationsResponse: ApiResponse<Notification[]> =
        await ApiService.get("/notifications");
      if (getNotificationsResponse.error) {
        // console.error(getNotificationsResponse.error);
        // return EventBus.$emit("error", getNotificationsResponse.message);
      }
      if (getNotificationsResponse.data)
        this.notifications = getNotificationsResponse.data;
    },
    async seeAllNotifications() {
      const seeAllNotificationsResponse: ApiResponse<Notification[]> =
        await ApiService.put("/notifications", {});
      if (seeAllNotificationsResponse.error) {
        console.error(seeAllNotificationsResponse.error);
        return EventBus.$emit("error", seeAllNotificationsResponse.message);
      }
    },
  },

  created() {
    this.getNotifications();
    EventBus.$on(
      "confetti",
      (title: string, emoji: string, message: string) => {
        // TODO
        // this.celebration(emoji + " " + message);
        this.celebrationTitle = title;
        this.celebrationEmoji = emoji;
        this.celebrationMessage = message;
        this.celebration();
      }
    );
    EventBus.$on("error", (message: string) => {
      return (this.snackbar = {
        color: "red",
        text: message,
        visible: true,
      });
    });
    EventBus.$on("info", (message: string) => {
      return (this.snackbar = {
        color: "blue",
        text: message,
        visible: true,
      });
    });
    this.$on("canInstall", (event: BeforeInstallPromptEvent) => {
      if (!this.showedInstallPrompt && this.$route.name === "home") {
        event.preventDefault();
        this.installPrompt = event;
        this.showInstallPrompt = true;
      }
    });
  },

  mounted() {
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
    // getToken(messaging, {
    //   vapidKey: process.env.VUE_APP_FIREBASE_WEB_PUSH_CERTIFICATE_KEY,
    // })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       // Send the token to your server and update the UI if necessary
    //       console.log("Notifications Token: ", currentToken);
    //       // ...
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       );
    //       // ...
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //   });
    // onMessage(messaging, (payload) => {
    //   console.log("Message received. ", payload);
    //   // ...
    // });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  watch: {
    $route: function (route) {
      this.showApp =
        (route.meta && route.meta.protected && route.meta.protected === true) ||
        false;
      this.appBarCustomButtons =
        (route.meta &&
          route.meta.customButtons &&
          route.meta.customButtons === true) ||
        false;
      // this.handleResize();
    },
    notificationsDrawer(navigationsDrawer) {
      if (navigationsDrawer) {
        this.seeAllNotifications();
      }
      this.getNotifications();
    },
  },
});
