import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { User } from "@/common/classes";

Vue.use(Vuex);

interface State {
  token: string | null;
  user: User | null;
}

const vuexLocalStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
});

const store = new Vuex.Store<State>({
  state: {
    token: null,
    user: null,
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    getToken: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
    getUserInitials: (state) => {
      return `${
        state.user?.firstName
          ? state.user.firstName.charAt(0).toUpperCase()
          : ""
      }${
        state.user?.lastName ? state.user.lastName.charAt(0).toUpperCase() : ""
      }`;
    },
  },
  mutations: {
    setToken(state, token: string) {
      state.token = token;
    },
    setUser(state, user: User) {
      state.user = user;
    },
    clearToken(state) {
      state.user = null;
      state.token = null;
    },
  },
  actions: {
    login({ commit }, payload: { token: string; user: User }) {
      const { token, user } = payload;
      commit("setToken", token);
      commit("setUser", user);
    },
    logout({ commit }) {
      commit("clearToken");
    },
  },
  plugins: [vuexLocalStorage.plugin],
});

export default store;
