
import Vue from "vue";
import { Icon } from "@iconify/vue2";
import { Notification } from "@/common/classes";
export default Vue.extend({
  name: "AppBar",

  components: {
    Icon,
  },

  props: {
    showApp: Boolean,
    customButtons: Boolean,
    title: String,
    icon: String,
    unseenNotifications: Number,
  },

  watch: {
    // unreadNotifications(unseenNotifications) {
    // },
  },
});
