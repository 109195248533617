
import Vue from "vue";
import ApiService from "@/services/ApiService";
import { EventBus } from "@/main";

export default Vue.extend({
  props: {
    src: {
      type: String,
      required: false,
    },
    height: Number,
    width: Number,
    contain: Boolean,
    rounded: Boolean,
    avatarInitials: {
      type: String,
      required: false,
    },
  },
  methods: {
    cssVars(): object {
      return {
        "--font-size": `${0.4 * this.height}px`,
      };
    },
    async getSecureImage() {
      if (!this.src) return;
      const fileResponse = await ApiService.get(`/files/${this.src}`);
      if (fileResponse.error) {
        this.imageSrc = null;
        console.error(fileResponse.error);
        //EventBus.$emit("error", "Error fetching image");
        return null;
      }
      return (this.imageSrc = fileResponse.data);
    },
    aspectRatio() {
      return this.rounded ? 1 : undefined;
    },
    style() {
      return `container-type: inline-size; ${
        this.avatarInitials ? "background: var(--v-progress-darken1);" : ""
      } ${this.rounded ? "border-radius: 50%;" : ""}`;
    },
  },

  data() {
    return {
      imageSrc: null as unknown as string | unknown,
    };
  },

  created() {
    this.getSecureImage();
  },

  watch: {
    src: function (src) {
      if (src) this.getSecureImage();
    },
  },
});
