
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
export default Vue.extend({
  name: "AppQRCodeDialog",

  components: {
    VueQRCodeComponent,
  },

  props: {
    title: String,
    qrCodeData: String,
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      visible: false,
    };
  },

  watch: {
    show(show) {
      this.visible = show;
    },
    visible(visible) {
      if (!visible) this.$emit("close");
    },
  },
});
